import React from 'react';

import { uuid } from 'uuidv4';
import {
  SearchComponent,
  ISearchComponent,
} from '../../components/SearchComponent';
import createModule from '../../components/SearchComponent/modules/Create';
import updateModule from '../../components/SearchComponent/modules/Update';
import deleteModule from '../../components/SearchComponent/modules/Delete';
import speakerLink from './modules/speakerLink';
import moderatorLink from './modules/ModeratorLink';
import { Container, Header, Body } from './styles';
import api from '../../services/api';
import updateUniqueModule from '../../components/SearchComponent/modules/UpdateUnique';

import {UpdateCoursesModule} from '../Course';
import { useSocket } from '../../hooks/Socket';
import Button from '../../components/Button';

interface ICreateModule {
  lineData: Record<string, any>;

 
  
}

interface ValidationRules {
  type: 'email' | 'required' | 'min' | 'max' | 'length';
  message?: string;
  quantity?: number;
}

interface ValidationSchema {
  name: string;
  type: 'string' | 'number' | 'boolean';
  label: string;
  rules: Array<ValidationRules>;
}

interface ILine {
  [key: string]: string;
}

const Lesson: React.FC = () => {
  const endpoint = '/lessons';
const {socket} = useSocket();
  const formSchemaUpdate = {
  
    title: {
      model: 'input',
      name: 'title',
      label: 'Título',
      defaultValue: '',
    },
    start_hour: {
      model: 'input',
      name: 'start_hour',
      label: 'Horário',
      
      defaultValue: '',
    },
    time: {
      model: 'input',
      name: 'time',
      label: 'Iniciar o vídeo no tempo: Ex: 1m30s',
      
      defaultValue: '',
    },
    room_name: {
      model: 'selectSimple',
      name: 'Sala',
      label: 'room_name',
      
      options: [
        { label: 'Espaço 1', value: 'Espaço 1'},
        { label: 'Espaço 2', value: 'Espaço 2'},
        { label: 'Espaço 3', value: 'Espaço 3'},
        { label: 'Espaço 4', value: 'Espaço 4'},
        { label: 'Espaço 5', value: 'Espaço 5'},
        { label: 'Espaço 6', value: 'Espaço 6'},

      ]
    
    },
    room_color: {
      model: 'selectSimple',
      name: 'Cor',
      label: 'room_color',
 
      options: [
        { label: 'Espaço 1 - Azul ', value: 'rgb(19, 136, 253)'},
        { label: 'Espaço 2 - Coral', value: 'coral'},
        { label: 'Espaço 3 - Vermelho', value: 'rgb(239, 33, 87)'},
        { label: 'Espaço 4 - Roxo', value: 'rgb(179, 0, 255)'},
        { label: 'Espaço 5 - Amarelo', value: 'rgb(255, 222, 0)'},
        { label: 'Espaço 6 - Verde', value: 'rgb(159, 252, 1)'},
        { label: 'Espaço 1 - Verde', value: 'rgb(0, 255, 164)'},

      ]
    
    },
    category_name: {
      model: 'input',
      name: 'category_name',
      label: 'Subtítulo',
      defaultValue: '',
    },
    status: {
      model: 'selectSimple',
      name: 'status',
      label: 'Status',
      defaultValue: '1',
      options: [
        { label: 'Em breve', value: 1 },
        { label: 'Ao vivo', value: 2 },
        { label: 'Finalizada', value: 3 },

      ]
    
    },
    class_name: {
      model: 'input',
      name: 'class_name',
      label: 'Classe CSS',
      defaultValue: '',
    },
    icon: {
      model: 'uploadImage',
      type: 'upload',
      name: 'icon',
      label: 'Ícone',
     
    },
    image: {
      model: 'uploadImage',
      type: 'upload',
      name: 'image',
      label: 'Foto',
    },
    category_id: {
      alias: 'category',
      model: 'selectApi',
      name: 'category_id',
      label: 'Categoria',
      defaultValue: 1,
      endpoint,
    },
    dificulty_id: {
      alias: 'dificulty',
      model: 'selectApi',
      name: 'dificulty_id',
      label: 'Nível',
      defaultValue: 1,
      endpoint,
    },
    video_type_id: {
      alias: 'video_type_id',
      model: 'selectApi',
      name: 'video_type_id',
      label: 'Tipo de Vídeo',
      defaultValue: 1,
      endpoint,
    },
    video_id: {
      model: 'input',
      name: 'video_id',
      label: 'Código do Vídeo',
      defaultValue: '',
    },
    chat_id: {
      model: 'input',
      name: 'chat_id',
      label: 'Chat de Referência',
      defaultValue: 0,
    },
    visible: {
      model: 'input',
      name: 'visible',
      label: 'Visível no Grid? 1 Não, 2 Sim',
      defaultValue: 2,
    },
    languages: {
      model: 'jsonList',
      type: 'text',
      name: 'languages',
      label: 'Línguas',
      list: [
        { label: 'Português', name: 'ptBr' },
        { label: 'Inglês', name: 'en' },
        { label: 'Espanhol', name: 'es' },
      ],
    },
    release_date: {
      model: 'datePicker',
      name: 'release_date',
      label: 'Data de Lançamento',
      defaultValue: '',
    },
    zoom_id: {
      model: 'input',
      name: 'zoom_id',
      label: 'ID do Zoom',
      defaultValue: '',
    },
    speakers : { name: 'speakers', type: 'text', model: 'ArrayInputIncluder', label: 'Email dos palestrantes' },

    teachers: {
      model: 'JsonInput',
      bodyRender: (data: Record<string, any>) => (
        <nav>
          <strong>
            (
{data.id})
{data.name}
          </strong>
          <br />
          {data.release_date_formatted || data.release_date}
        </nav>
      ),
      searchRender: (data: Record<string, any>) => (
        <>
          <strong>
            (
{data.id})
{' '}
{data.name}
          </strong>
          <br />
          {data.release_date_formatted || data.release_date}
        </>
      ),
      storageType: 'json',
      endpoint: `${endpoint}/feedSelect/teachers`,
      name: 'teachers',
      label: 'Professores',
    },
  };

  const formSchema = {
  
    title: {
      model: 'input',
      name: 'title',
      label: 'Título',
      defaultValue: '',
    },
    start_hour: {
      model: 'input',
      name: 'start_hour',
      label: 'Horário',
      
      defaultValue: '',
    },
    time: {
      model: 'input',
      name: 'time',
      label: 'Iniciar o vídeo no tempo: Ex: 1m30s',
      
      defaultValue: '',
    },
    room_name: {
      model: 'selectSimple',
      name: 'Sala',
      label: 'room_name',
   
      options: [
        { label: 'Espaço 1', value: 'Espaço 1'},
        { label: 'Espaço 2', value: 'Espaço 2'},
        { label: 'Espaço 3', value: 'Espaço 3'},
        { label: 'Espaço 4', value: 'Espaço 4'},
        { label: 'Espaço 5', value: 'Espaço 5'},
        { label: 'Espaço 6', value: 'Espaço 6'},

      ]
    
    },
    room_color: {
      model: 'selectSimple',
      name: 'Cor',
      label: 'room_color',

      options: [
        { label: 'Espaço 1 - Azul ', value: 'rgb(19, 136, 253)'},
        { label: 'Espaço 2 - Coral', value: 'coral'},
        { label: 'Espaço 3 - Vermelho', value: 'rgb(239, 33, 87)'},
        { label: 'Espaço 4 - Roxo', value: 'rgb(179, 0, 255)'},
        { label: 'Espaço 5 - Amarelo', value: 'rgb(255, 222, 0)'},
        { label: 'Espaço 6 - Verde', value: 'rgb(159, 252, 1)'},
        { label: 'Espaço 1 - Verde', value: 'rgb(0, 255, 164)'},

      ]
    
    },
    category_name: {
      model: 'input',
      name: 'category_name',
      label: 'Subtítulo',
      defaultValue: '',
    },
    class_name: {
      model: 'input',
      name: 'class_name',
      label: 'Classe CSS',
      defaultValue: '',
    },
    status: {
      model: 'selectSimple',
      name: 'status',
      label: 'Status',
      defaultValue: '1',
      options: [
        { label: 'Em breve', value: 1 },
        { label: 'Ao vivo', value: 2 },
        { label: 'Finalizada', value: 3 },

      ]
    
    },
    category_id: {
      alias: 'category_id',
      model: 'selectApi',
      name: 'category_id',
      label: 'Categoria',
      defaultValue: 1,
      endpoint,
    },
    dificulty_id: {
      alias: 'dificulty_id',
      model: 'selectApi',
      name: 'dificulty_id',
      label: 'Nível',
      defaultValue: 1,
      endpoint,
    },
    video_type_id: {
      alias: 'video_type_id',
      model: 'selectApi',
      name: 'video_type_id',
      label: 'Tipo de Vídeo',
      defaultValue: 1,
      endpoint,
    },
    video_id: {
      model: 'input',
      name: 'video_id',
      label: 'Código do Vídeo',
      defaultValue: '',
    },
    zoom_id: {
      model: 'input',
      name: 'zoom_id',
      label: 'ID do Zoom',
      defaultValue: '',
    },
    speakers : { name: 'speakers', type: 'text', model: 'ArrayInputIncluder', label: 'Email dos palestrantes' },
    chat_id: {
      model: 'input',
      name: 'chat_id',
      label: 'Chat de Referência',
      defaultValue: 0,
    },
    visible: {
      model: 'input',
      name: 'visible',
      label: 'Visível no Grid? 1 Não, 2 Sim',
      defaultValue: 2,
    },
    languages: {
      model: 'jsonList',
      type: 'text',
      name: 'languages',
      label: 'Línguas',
      list: [
        { label: 'Português', name: 'ptBr' },
        { label: 'Inglês', name: 'en' },
        { label: 'Espanhol', name: 'es' },
      ],
    },
    release_date: {
      model: 'datePicker',
      name: 'release_date',
      label: 'Data de Lançamento',
      defaultValue: '',
    },
    teachers: {
      model: 'JsonInput',
      bodyRender: (data: Record<string, any>) => (
        <nav>
          <strong>
            (
{data.id})
{data.name}
          </strong>
          <br />
          {data.release_date_formatted || data.release_date}
        </nav>
      ),
      searchRender: (data: Record<string, any>) => (
        <>
          <strong>
            (
{data.id})
{' '}
{data.name}
          </strong>
          <br />
          {data.release_date_formatted || data.release_date}
        </>
      ),
      storageType: 'json',
      endpoint: `${endpoint}/feedSelect/teachers`,
      name: 'teachers',
      label: 'Professores',
    },
  };

  const formValidation: Record<string, ValidationSchema> = {
    title: {
      name: 'title',
      type: 'string',
      label: 'Título',
      rules: [{ type: 'required' }],
    },
    video_id: {
      name: 'video_id',
      type: 'string',
      label: 'Código do Vìdeo',
      rules: [{ type: 'required' }],
    },
  };

  const key2 = uuid();

  const schema2: Record<string, any> = {
    id: { ref: 'id', column: 'id', label: 'ID' },
    hash: { ref: 'hash', column: 'hash', label: 'Hash', show: false },
    title: { ref: 'title', column: 'title', label: 'Título' },
    room_name: { ref: 'room_name', column: 'room_name', label: 'Sala' },
    release_date: { ref: 'release_date', column: 'release_date', mask: 'date', label: 'Data' },
    start_hour: { ref: 'start_hour', column: 'start_hour', label: 'Horário' },
    
    video_type_id: {
      ref: 'video_type_id',
      column: 'video_type',
      includeColumn: 'title',
      label: 'Tipo de Vídeo',
    },
    video_id: { ref: 'video_id', column: 'video_id', label: 'ID do Vídeo' },
    class_name: { ref: 'class_name', column: 'class_name',  label: 'Classe' },
    createdAt: {
      ref: 'created_at',
      column: 'createdAt',
      label: 'Data de Criação',
      mask: 'date',
    },
  };

   function updateLessonModule({

    lineData,
  
   
  }: ICreateModule): JSX.Element {

    const clearLessonCache = () => {
      socket.emit('clearChatCache', {room_id : lineData.id});
    }

return <Button style={{padding: '5px'}} onClick={() => clearLessonCache()}>Limpar Cache</Button>

  }
 

  const config2: ISearchComponent = {
    idKey: key2,
    title: 'aulas',
    endpoint: '/lessons',
    schema: schema2,
    autoStart: true,
    headModules: [
      () =>
        createModule({
          title: 'Aulas',
          formSchema,
          validationSchema: formValidation,
          endpoint,
        }),
      
    ],
    bodyModules: [
      (lineData: Record<string, any>) =>
      speakerLink({
          lineData,
          
        }),
        (lineData: Record<string, any>) =>
        moderatorLink({
            lineData,
            
          }),
      
      (lineData: Record<string, any>) =>
        updateUniqueModule({
          lineData,
          title: 'Aulas',
          formSchema: formSchemaUpdate,
          validationSchema: {},
          endpoint,
        }),
        (lineData: Record<string, any>) =>
        updateLessonModule({
          lineData,
  
        
        }),
      (lineData: Record<string, any>) =>
        deleteModule({
          lineData,
          title: 'Aula',
          formSchema,
          validationSchema: formValidation,
          endpoint,
        }),
    ],
  };

  return (
    <Container>
      <UpdateCoursesModule/>
      <SearchComponent {...config2} />
    </Container>
  );
};

export default Lesson;
