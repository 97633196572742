import React, { useRef } from 'react';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import Input from '../Input';
import MaskedInput from '../MaskedInput';
import SelectApi from '../SelectApi';
import SelectSimple from '../SelectSimple';
import JsonInputContainer from '../JsonInput';
import Datepicker from '../DatePicker';
import DatepickerHour from '../DatePickerHour';
import Hourpicker from '../HourPicker';
import { Field } from './styles';
import Button from '../Button';
import CurrencyInput from '../MaskedCurrencyInput';
import RichText from '../RichText';
import UploadImage from '../UploadImage';
import ImageGalleryInput from '../ImageGalleryInput';
import JsonList from '../JsonList';
import JsonListIncluder from '../JsonListIncluder';
import JsonListMultiple from '../JsonListMultiple';
import ArrayInputIncluder from '../ArrayInputIncluder';
interface IInputModel {
  model: string;
  name: string;
  type: string;
  callback?: Function;
  mask?: string;
  label: string;
}

interface IListInput {
  label: string;
  name: string;
}

interface IInputJsonModel {
  model: string;
  name: string;
  type: string;
  list: Array<IListInput>;
  callback?: Function;
  mask?: string;
  label: string;
}

interface ISelectOptions {
  text: string;
  value: string;
}

interface IJsonInputModel {
  model: string;
  name: string;
  storageType: 'id' | 'json';
  callback?: Function;
  label: string;
  endpoint: string;
  searchRender: Function;
  bodyRender: Function;
}

interface ISelectApiModel {
  model: string;
  name: string;
  type: string;
  callback?: Function;
  mask?: string;
  label: string;
  endpoint: string;
  loadOptions: Function;
  alias: string;
}

interface IImage {
  url?: string;
  title?: string;
  description?: string;
  link_url?: string;
}


interface IInputImageModel {
  model: string;
  name: string;
  type: string;
  list: Array<IImage>;
  callback?: Function;
  mask?: string;
  label: string;
}

interface ISelectSimpleModel {
  model: string;
  name: string;
  type: string;
  callback?: Function;
  mask?: string;
  label: string;
  options: Array<ISelectOptions>;
}

interface IInput {
  name: string;
  type: string;
  callback?: Function;
}

interface IMaskedInput {
  name: string;
  type: string;
  callback?: Function;
  mask: string;
}

interface IFormComponent {
  schema: Record<string, IInputModel>;
  dataInfo?: Record<string, IInputModel>;
  setCallback?: Function;
}

export const sanitizeData = {
  currencyInput: value =>
    value ? parseInt(value.toString().replace(/[^0-9]/g, ''), 10) : 0,
};

export const sanitizeDataObject = (
  data: Record<string, any>,
  formSchema: Record<string, any>,
) => {
  const newData = Object.keys(data).reduce(
    (prev: Record<string, any>, key: string) => {
      prev[key] =
        formSchema[key] &&
        formSchema[key].model &&
        sanitizeData[formSchema[key].model]
          ? sanitizeData[formSchema[key].model](data[key])
          : data[key];
      return prev;
    },
    {},
  );
  return newData;
};

const FormComponent: React.FC<IFormComponent> = ({
  schema,
  dataInfo,
  setCallback,
}): JSX.Element => {
  const formRef = useRef<FormHandles>(null);
  const handleSubmit = async (data: Record<string, any>): Promise<void> => {
    if (setCallback) {
      await setCallback(data, formRef);
    }
  };

  const ValidInputs = (input: IInputModel): JSX.Element => {
    const inputs: Record<string, Function> = {
      input: (inputInfo: IInputModel) => (
        <Field>
          <label>{inputInfo.label}</label>
          <Input
            formRef={formRef}
            placeholder={inputInfo.label}
            name={inputInfo.name}
            type={inputInfo.type || 'text'}
            setCallback={inputInfo.callback || undefined}
          />
        </Field>
      ),
      jsonList: (inputInfo: IInputJsonModel) => (
        <Field>
          <label>{inputInfo.label}</label>
          <JsonList
            formRef={formRef}
            placeholder={inputInfo.label}
            list={inputInfo.list}
            name={inputInfo.name}
            setCallback={inputInfo.callback || undefined}
          />
        </Field>
      ),
      ArrayInputIncluder: (inputInfo: IInputJsonModel) => (
        <Field>
          <label>{inputInfo.label}</label>
          <ArrayInputIncluder
            formRef={formRef}
            placeholder={inputInfo.label}
            list={inputInfo.list}
            name={inputInfo.name}
            setCallback={inputInfo.callback || undefined}
          />
        </Field>
      ),
      jsonListMultiple: (inputInfo: IInputJsonModel) => (
        <Field>
          <label>{inputInfo.label}</label>
          <JsonListMultiple
            formRef={formRef}
            placeholder={inputInfo.label}
            list={inputInfo.list}
            name={inputInfo.name}
            setCallback={inputInfo.callback || undefined}
          />
        </Field>
      ),
      jsonListIncluder: (inputInfo: IInputJsonModel) => (
        <Field>
          <label>{inputInfo.label}</label>
          <JsonListIncluder
            formRef={formRef}
            placeholder={inputInfo.label}
            list={inputInfo.list}
            name={inputInfo.name}
            setCallback={inputInfo.callback || undefined}
          />
        </Field>
      ),
      imageGalleryInput: (inputInfo: IInputImageModel) => (
        <Field>
          <label>{inputInfo.label}</label>
          <ImageGalleryInput
            formRef={formRef}
            placeholder={inputInfo.label}
            name={inputInfo.name}
            setCallback={inputInfo.callback || undefined}
            list={inputInfo.list}
          />
        </Field>
      ),
      uploadImage: (inputInfo: IInputModel) => (
        <Field>
          <label>{inputInfo.label}</label>
          <UploadImage
            formRef={formRef}
            placeholder={inputInfo.label}
            name={inputInfo.name}
            setCallback={inputInfo.callback || undefined}
          />
        </Field>
      ),
      richText: (inputInfo: IInputModel) => (
        <Field>
          <label>{inputInfo.label}</label>
          <RichText
            formRef={formRef}
            placeholder={inputInfo.label}
            name={inputInfo.name}
            type={inputInfo.type || 'text'}
            setCallback={inputInfo.callback || undefined}
          />
        </Field>
      ),
      maskedInput: (inputInfo: IInputModel) => (
        <Field>
          <label>{inputInfo.label}</label>
          <MaskedInput
            formRef={formRef}
            placeholder={inputInfo.label}
            name={inputInfo.name}
            type={inputInfo.type}
            mask={inputInfo.mask || ''}
            setCallback={inputInfo.callback || undefined}
          />
        </Field>
      ),
      currencyInput: (inputInfo: IInputModel) => (
        <Field>
          <label>{inputInfo.label}</label>
          <CurrencyInput
            formRef={formRef}
            placeholder={inputInfo.label}
            name={inputInfo.name}
            type={inputInfo.type}
            mask={inputInfo.mask || ''}
            setCallback={inputInfo.callback || undefined}
          />
        </Field>
      ),

      selectSimple: (inputInfo: ISelectSimpleModel) => (
        <Field>
          <label>{inputInfo.label}</label>
          <SelectSimple
            formRef={formRef}
            label={inputInfo.label}
            name={inputInfo.name}
            options={inputInfo.options}
            setCallback={inputInfo.callback || undefined}
          />
        </Field>
      ),

      selectApi: (inputInfo: ISelectApiModel) => (
        <Field>
          <label>{inputInfo.label}</label>
          <SelectApi
            formRef={formRef}
            alias={inputInfo.alias}
            label={inputInfo.label}
            name={inputInfo.name}
            endpoint={inputInfo.endpoint}
            setCallback={inputInfo.callback || undefined}
          />
        </Field>
      ),
      JsonInput: (inputInfo: IJsonInputModel) => {
        const {
          searchRender,
          bodyRender,
          storageType = 'id',
          endpoint,
          name,
          label,
        } = inputInfo;
        return (
          <Field>
            <label>{label}</label>
            <JsonInputContainer
              storageType={storageType}
              name={name}
              endpoint={endpoint}
              searchRender={searchRender}
              bodyRender={bodyRender}
            />
          </Field>
        );
      },
      datePicker: (inputInfo: IInputModel) => (
        <Field>
          <label>{inputInfo.label}</label>
          <Datepicker name={inputInfo.name} />
        </Field>
      ),
      datePickerHour : (inputInfo: IInputModel) => (
        <Field>
          <label>{inputInfo.label}</label>
          <DatepickerHour name={inputInfo.name} />
        </Field>
      ),
      hourPicker: (inputInfo: IInputModel) => (
        <Field>
          <label>{inputInfo.label}</label>
          <Hourpicker name={inputInfo.name} />
        </Field>
      ),
    };
    return inputs[input.model](input);
  };

  const getDataInfo = (
    schemaList: Record<string, any>,
  ): Record<string, any> => {
    const data = Object.keys(schemaList).reduce(
      (prev: Record<string, any>, elem: string) => {
        if (schemaList[elem]) {
          prev[schemaList[elem].name] = schemaList[elem].defaultValue || '';
        }
        return prev;
      },
      {},
    );

    return data;
  };

  const getForm = (schemaList: Record<string, IInputModel>) => {
    const data = dataInfo || getDataInfo(schemaList);

    return (
      <>
        {Object.keys(schemaList).map(key => (
          <Form
            style={{
              minWidth: '100%',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              borderTop: '2px solid #444',
              padding: '10px 0',
            }}
            ref={formRef}
            initialData={data}
            onSubmit={handleSubmit}
          >
            {ValidInputs(schemaList[key])}

            <Button style={{ maxWidth: '70px', margin: 0 }} type="submit">
              Salvar
            </Button>
          </Form>
        ))}
      </>
    );
  };

  return getForm(schema);
};

export default FormComponent;
