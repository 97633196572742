import React, {
  InputHTMLAttributes,
  useEffect,
  useRef,
  useState,
  useCallback,
} from 'react';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { Container, Modal } from './styles';
import { useSocket } from '../../hooks/Socket';

import { useToast } from '../../hooks/Toast';
import Button from '../Button';
import Input from '../Input';

interface ICreator {
  room_id?: number;
  module_id?: number;
}

export const RoomChangeContainer: React.FC<ICreator> = () => {
  const { socket } = useSocket();

  useEffect(() => {
    socket.on('getRoomChange', state => {
      if (state.id) {
        window.location.href = `/dashboard/${state.id}`;
      }
    });

    socket.on('getRoomChangeAll', state => {
      if (state.id) {
        window.location.href = `/dashboard/${state.id}`;
      }
    });
  }, []);

  return <></>;
};

export const RoomChangeCreator: React.FC<ICreator> = ({
  room_id = 0,
  module_id = 1,
}) => {
  const { socket } = useSocket();
  const { addToast } = useToast();
  const [creatorActive, setCreatorActive] = useState(false);

  const formRef = useRef<FormHandles>(null);

  const handleChange = data => {
    if (!data.destination_room) {
      addToast({ type: 'error', title: 'Defina uma sala de destino' });
      return;
    }

    if (data.destination_room) {
      socket.emit('sendRoomChange', { ...data });
      addToast({ type: 'success', title: 'Enviado com sucesso' });
      setCreatorActive(false);
    }
  };

  return (
    <>
      {!creatorActive && (
        <Button onClick={() => setCreatorActive(true)} type="button">
          Redirecionar usuários da sala
        </Button>
      )}
      {creatorActive && (
        <Modal top right fullHeight>
          <div>
            <button type="button" onClick={() => setCreatorActive(false)}>
              Fechar
{' '}
            </button>
            <Form
              ref={formRef}
              onSubmit={handleChange}
              initialData={{ current_room: room_id, destination_room: room_id }}
            >
              <Input
                name="current_room"
                placeholder="ID Sala Atual (Zero para Global)"
              />
              <Input name="destination_room" placeholder="ID de destino" />
              <Button type="submit">Enviar</Button>
            </Form>
          </div>
        </Modal>
      )}
    </>
  );
};
