import React, { Dispatch, useRef, useCallback } from 'react';

import { Form } from '@unform/web';
import {
  FiMail,
  FiLock,
  FiUser,
  FiArrowRight,
  FiArrowLeft,
} from 'react-icons/fi';
import { Link } from 'react-router-dom';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { useAuth } from '../../../../hooks/Auth';
import Input from '../../../../components/Input';
import { useToast } from '../../../../hooks/Toast';
import { AnimatedDiv } from '../../styles';
import { LoginContent } from './styles';
import getValidationErrors from '../../../../utils/getValidationErrors';

import Button from '../../../../components/Button';
import { BuyContext } from '../../index';
import api from '../../../../services/api';

interface ComponentProps {
  step: number[];
  setStep: Dispatch<React.SetStateAction<number[]>>;
  buyContext: BuyContext;
  setBuyContext: Dispatch<React.SetStateAction<BuyContext>>;
}

interface SignInData {
  email: string;
  password: string;
}

interface LoginProps {
  name: string;
  email: string;
  password: string;
}

const LoginContainer: React.FC<ComponentProps> = ({
  buyContext,
  setBuyContext,
  setStep,
  step,
}) => {
  const { signIn } = useAuth();
  const { addToast } = useToast();
  const formRef = useRef<FormHandles>(null);
  const { productType } = buyContext;

  const handleLogin = useCallback(
    async (data: SignInData) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          email: Yup.string()
            .required('Email obrigatório')
            .email('Preencha um email válido'),
          password: Yup.string().required('Senha obrigatória'),
        });

        await schema.validate(data, { abortEarly: false });

        const { email, password } = data;

        await signIn({ email, password });
        setBuyContext({ ...buyContext, isLogged: true });

        setStep([3, 4]);
        return;
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
        }

        addToast({
          type: 'error',
          title: 'Erro na autenticação',
          description: 'Ocorreu um erro ao fazer login',
        });
      }
    },
    [signIn, addToast, buyContext, setBuyContext, setStep],
  );

  const handleNewLogin = useCallback(
    async (data: LoginProps) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          name: Yup.string().required('Nome obrigatório'),
          email: Yup.string()
            .required('Email obrigatório')
            .email('Preencha um email válido'),
          password: Yup.string().required('Senha obrigatória'),
        });

        await schema.validate(data, { abortEarly: false });

        const { name, email, password } = data;

        const newUser = await api
          .post('/signup', { name, email, password })
          .catch(error => {
            addToast({
              type: 'error',
              title: error.response.data.message,
            });
            return false;
          });

        if (!newUser) {
          return false;
        }
        await signIn({ email, password });
        setBuyContext({ ...buyContext, login: { name, email, password } });
        if (productType === 'free') {
          setStep([3, 6]);
        } else {
          setStep([3, 4]);
        }

        return false;
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          console.log(err);
          formRef.current?.setErrors(errors);
        }

        addToast({
          type: 'error',
          title: 'Erro na validação',
          description: 'Ocorreu um erro ao tentar validar',
        });
      }
    },
    [addToast, buyContext, setBuyContext, setStep, signIn],
  );

  return (
    <AnimatedDiv visible={step[1] === 3}>
      <LoginContent>
        <div>
          {productType === 'paid' && (
            <aside>
              <Form ref={formRef} onSubmit={handleLogin}>
                <h1>Já tenho conta</h1>
                <Input icon={FiMail} name="email" placeholder="E-mail" />
                <Input
                  icon={FiLock}
                  name="password"
                  type="password"
                  placeholder="senha"
                />
                <Button type="submit">Entrar</Button>
                <Link to="/recuperar_senha">Esqueci minha senha</Link>
              </Form>
            </aside>
          )}
          <aside>
            <Form ref={formRef} onSubmit={handleNewLogin}>
              <h1>Faça seu cadastro</h1>
              <Input icon={FiUser} name="name" placeholder="Nome" />
              <Input icon={FiMail} name="email" placeholder="E-mail" />
              <Input
                icon={FiLock}
                name="password"
                type="password"
                placeholder="senha"
              />
              <Button type="submit">
                {productType === 'free' ? 'Finalizar' : 'Continuar'}

                <FiArrowRight />
              </Button>
              <Button
                type="button"
                onClick={() => {
                  setStep([1, step[0]]);
                }}
              >
                <FiArrowLeft />
                Voltar
              </Button>
            </Form>
          </aside>
        </div>
      </LoginContent>
    </AnimatedDiv>
  );
};

export default LoginContainer;
