import React, { useState } from 'react';
import * as Yup from 'yup';
import { FormHandles } from '@unform/core';
import { uuid } from 'uuidv4';
import { useModal } from '../../../../hooks/Modal';
import FormComponent, { sanitizeDataObject } from '../../../FormComponent';
import { createSchemaByForm, IYup } from '../../../../utils/SchemaCreator';
import getValidationErrors from '../../../../utils/getValidationErrors';
import api from '../../../../services/api';
import { useToast } from '../../../../hooks/Toast';
import { useSearch } from '../../../../hooks/Search';
import { AxiosInstance } from 'axios';

interface ICreateModule {
  apiRef ?: AxiosInstance,
  title: string;
  formSchema: Record<string, any>;
  endpoint: string;
  validationSchema: Record<string, IYup>;
  buttonContent?: JSX.Element;
  addCallback?: Function;
}

export default function CreateModule({
  apiRef = api,
  title,
  formSchema,
  validationSchema,
  endpoint,
  buttonContent = <span>Novo</span>,
  addCallback = () => {},
}: ICreateModule): JSX.Element {
  const { reloadSearchAll } = useSearch();
  const { addModal, removeModal } = useModal();
  const { addToast } = useToast();
  const key = uuid();

  const setCallback = async (
    data: Record<string, any>,
    formRef: React.RefObject<FormHandles>,
  ): Promise<void> => {
    try {
      const newData = sanitizeDataObject(data, formSchema);

      const schema = createSchemaByForm(validationSchema);
      await schema.validate(newData, { abortEarly: false });

      const response = await apiRef.post(endpoint, newData);

      if (response.status !== 200) {
        return addToast({ type: 'error', title: 'Erro ao salvar' });
      }

      removeModal(key);

      addToast({ type: 'success', title: 'Salvo com sucesso' });
      await reloadSearchAll();
      await addCallback();
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);

        formRef.current?.setErrors(errors);
        return;
      }

      return addToast({ type: 'error', title: err.response.data.error });
    }
  };

  const handleCreate = (): void => {
    const content = (
      <FormComponent schema={formSchema} setCallback={setCallback} />
    );
    if (content) {
      const keyModal = addModal({ title, content, key });
    }
  };

  const moduleCreate = (): JSX.Element => (
    <button type="button" onClick={handleCreate}>
      {buttonContent}
    </button>
  );

  return moduleCreate();
}
