import React from 'react';
import { Wrapper, Background } from './styles';
import { Pattern } from '../../../styles/ThemeComponents';
import Header from '../../../components/Header';

const PageLayout: React.FC = ({ children }) => {
  return <Wrapper>{children}</Wrapper>;
};

export default PageLayout;
