import styled, { css } from 'styled-components';
import Tooltip from '../Tooltip';
import { ThemeDefinitions } from '../../styles/ThemeComponents';



export const Container = styled.div`
  display: flex;
  justify-content: stretch;
  align-items: center;
  flex-direction: row;

  background: ${ThemeDefinitions.input.background};
  color: ${ThemeDefinitions.input.color};
  border-radius: 10px;
  border: ${ThemeDefinitions.input.border};
  padding: 0px 16px;
  position: relative;

  > span {

    transition: 0.2s all;
    position: absolute;
    left: 16px; top: 15px;
    font-size: 0px;
  }



  & + div {
    margin-top: 8px;
  }

  svg {
    font-size: 16px;
    margin-right: 16px;
  }



  input {
    width: 100%;
    border: 0;
    color: ${ThemeDefinitions.input.color};
    padding: 16px;
    background: transparent;

    &::placeholder {
      color: #eee;
    }
  }
`;

export const Error = styled(Tooltip)`
  margin-left: 16px;
  height: 20px;

  span {
    background: #c53030;
    color: #fff;

    &::before {
      border-color: #c53030 transparent;
    }
  }
  svg {
    margin-right: 0;
    color: #c53030;
    font-size: 20px;
  }
`;

export const Field = styled.div`
  display: flex;
  justify-content: stretch;
  flex-direction: row;
  width: 100%;
  align-items: center;

  > label {
    margin-bottom: 5px;
    display: flex;
    max-width: 200px;
    min-width: 150px;
  }

  > div {
    width: 100%;
    margin: 0 5px;

    > input,
    > select,
    > textarea {
      margin-top: 0;
    }
  }
`;