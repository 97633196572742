import styled, { css } from 'styled-components';
import Tooltip from '../Tooltip';
import { ThemeDefinitions } from '../../styles/ThemeComponents';

interface ContainerProps {
  isFocused: boolean;
  isFilled: boolean;
  isErrored: boolean;
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  justify-content: stretch;
  align-items: center;

  background: ${ThemeDefinitions.input.background};
  color: ${ThemeDefinitions.input.color};
  border-radius: 10px;
  border: ${ThemeDefinitions.input.border};
  padding: 0px 16px;

  position: relative;

> span {

  transition: 0.2s all;
  position: absolute;
  left: 16px; top: 15px;
  font-size: 0px;
}

  ${props =>
    props.isErrored &&
    css`
      border-color: #c53030;

      > span {
        display: inline-block;
        position: absolute;
        top: 3px;
        left: 16px;
        color: #fff;
        font-size: 10px;
      }
    `}

  ${props =>
    props.isFocused &&
    css`
      color: ${ThemeDefinitions.input.colorFocused};
      border-color: ${ThemeDefinitions.input.borderColorFocused};

      > span {
        display: inline-block;
        position: absolute;
        top: 3px;
        left: 16px;
        color: #fff;
        font-size: 10px;
      }
    `}

    ${props =>
      props.isFilled &&
      css`
        color: ${ThemeDefinitions.input.colorFilled};

        > span {
          display: inline-block;
          position: absolute;
          top: 3px;
          left: 16px;
          color: #fff;
          font-size: 10px;
        }
      `}



  & + div {
    margin-top: 8px;
  }

  svg {
    font-size: 16px;
    margin-right: 16px;
  }



  textarea {
    width: 100%;
    min-height: 200px;
    border: 0;
    color: ${ThemeDefinitions.input.color};
    padding: 16px;
    background: transparent;

    &::placeholder {
      color: ${ThemeDefinitions.input.placeholder};
    }
  }
`;

export const Error = styled(Tooltip)`
  margin-left: 16px;
  height: 20px;

  span {
    background: #c53030;
    color: #fff;

    &::before {
      border-color: #c53030 transparent;
    }
  }
  svg {
    margin-right: 0;
    color: #c53030;
    font-size: 20px;
  }
`;
