import React, { useCallback, useRef, useEffect, useState } from 'react';
import {
  FiLogIn,
  FiMail,
  FiLock,
  FiKey,
  FiMessageCircle,
} from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { Link, useHistory } from 'react-router-dom';
import { rgb } from 'polished';
import {
  FaConnectdevelop,
  FaLock,
  FaHandPointUp,
  FaCertificate,
  FaTicketAlt,
  FaVideo,
  FaAward,
  FaSlideshare,
  FaKey,
} from 'react-icons/fa';
import { MdColorLens, MdMessage } from 'react-icons/md';
import { AiFillSafetyCertificate } from 'react-icons/ai';
import { IconType } from 'react-icons/lib';
import { ContactIcons, WhatsappIcon } from '../../components/Menu';
import Whatsapp from '../../components/Whatsapp';
import { logo } from '../../config';
import { Container, ContainerWhite } from './styles';
import Input from '../../components/Input';
import Button from '../../components/Button';
import getValidationErrors from '../../utils/getValidationErrors';

import { useAuth } from '../../hooks/Auth';
import { useToast } from '../../hooks/Toast';

import { Pattern } from '../../styles/ThemeComponents';
import ShowDiv from '../../components/ShowOnScroll';
import VideoBackground from '../../components/VideoBackground';
import TextPopper from '../../components/TextPopper';
import Scroller from '../../components/Scroller';

import Publicas from './modules/publicas';
import Vimeo from '../../components/Vimeo';

interface SignInData {
  email: string;
  password: string;
}

interface IPublicData {
  icon: IconType;
  title: string;
  text: string;
}

const SignIn: React.FC = () => {
  const history = useHistory();
  const { signIn } = useAuth();
  const { addToast } = useToast();
  const formRef = useRef<FormHandles>(null);
  const [cardLayout, setCardLayout] = useState([] as Array<JSX.Element>);

  const handleSubmit = useCallback(
    async (data: SignInData) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          email: Yup.string()
            .required('Email obrigatório')
            .email('Preencha um email válido'),
          password: Yup.string().required('Senha obrigatória'),
        });

        await schema.validate(data, { abortEarly: false });

        const { email, password } = data;

        await signIn({ email, password });
        history.push('/dashboard');
        return;
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
        }

        addToast({
          type: 'error',
          title: 'Erro na autenticação',
          description: 'Ocorreu um erro ao fazer login',
        });
      }
    },
    [signIn, addToast, history],
  );

  const buildCard: React.FC<IPublicData> = ({ icon, title, text }) => {
    return (
      <div
        onClick={() =>
          setCardLayout([<Publicas icon={icon} text={text} title={title} />])}
      >
        {icon({})}
        <p>{title}</p>
      </div>
    );
  };

  return (
    <>
      <ContainerWhite id="sobre">
        <div className="p50 p600 ">
          <h2>APRESENTAÇÃO</h2>
          <div className="separator" />
          <p>
            Em sua já conhecida busca por inovação, neste ano, a OAB/RS realiza
            a IX Conferência Estadual da Advocacia, que traz como eixo central
            os impactos da sociedade tecnológica para a advocacia no contexto de
            pandemia, Poder Judiciário e o Pós-Crise. Devido ao isolamento
            social, o evento acontece no formato completamente online.
            <br />
            <br />
            A Conferência abordará, em sua programação, temas relacionados à
            privacidade, ao tratamento de dados, à advocacia 4.0, à Inteligência
            Artificial, às inovações tecnológicas, dentre outros como democracia
            digital, reforma tributária, startups, lawtechs, possibilitando além
            de capacitação à advocacia, reflexões para o enfrentamento dos
            desafios da era digital.
            <br />
            <br />
            As palestras ocorrerão por meio de videoconferências em tempo real,
            sendo que as conclusões dos painéis serão publicadas posteriormente
            em formato de e-book acessível a toda Advocacia.
            <br />
            <br />O evento é gratuito, tendo-se presente a grande crise
            financeira que assola a sociedade, reflexo também da pandemia que
            estamos enfrentando, e apenas necessita de inscrição prévia para
            receber a certificação.
          </p>
          <div className="separator" />
          <p>
            <strong>Coordenação-Geral: </strong>
            Ricardo Breier – Presidente da OAB/RS
            <br />
            <br />
            <strong>Coordenação Científica e Executiva: </strong>
            Fabiana Azevedo da Cunha Barth – Secretária-Geral Adjunta da OAB/RS
            <br />
            <br />
            <strong>Organização e Produção:</strong>
            Departamento de Eventos da OAB/RS
            <br />
            Comunicação Social da OAB/RS
          </p>
        </div>
        <div className="p50 p600">
          <Vimeo id="443007047" />
        </div>
      </ContainerWhite>
    </>
  );
};
export default SignIn;
